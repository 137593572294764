@import '../../../styles/var';

.wrap {
    position: relative;
    width: 100%;
    max-width: 950px;
    margin: 80px auto 0;

    @media (max-width: $md4+px) {
        margin-top: 50px;
    }

    @media (max-width: $md6+px) {
        margin-top: 30px;
    }

    .title {}

    .tabs {
        margin-top: 20px;

        .tab {
            border-bottom: 1px solid rgba(0, 0, 0, 0.30);
            padding: 15px 0;
            cursor: pointer;
            transition: all .2s;

            &.active {
                .text {
                    display: block;
                }

                .status::after {
                    transform: rotate(0) !important;
                }
            }

            .head {
                display: grid;
                grid-template-columns: 1fr 20px;
                gap: 20px;
                align-items: center;

                @media (max-width: $md6+px) {
                    grid-template-columns: 1fr 18px;
                }

                .subtitle {
                    font-weight: 500;
                    line-height: 130%;
                }

                .status {
                    position: relative;
                    width: 100%;
                    aspect-ratio: 1/1;

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: #434343;
                        width: 100%;
                        height: 2px;
                        top: calc(50% - 1px);
                        left: 0;
                    }

                    &::after {
                        transform: rotate(90deg);
                        transition: all .2s;
                    }
                }
            }

            .text {
                display: none;
                margin-top: 15px;
                font-weight: 400;
                max-width: 697px;
            }
        }
    }
}