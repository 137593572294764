@import '../../../styles/var';

.wrap {
    position: relative;
    height: 298px;
    width: 100%;
    max-width: 2000px;
    margin: 125px auto 0;

    @media (max-width: $md3+px) {
        margin-top: 112px;
    }

    @media (max-width: $md4+px) {
        height: 250px;
        margin-top: 108px;
    }

    @media (max-width: $md5+px) {
        margin-top: 140px;
        height: 220px;
    }

    @media (max-width: $md6+px) {
        margin-top: 130px;
        height: 154px;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/img/FaqPage/banner.avif');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}