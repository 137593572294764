@import '../../../styles/var';

.wrap {
    margin-top: 230px;
    display: grid;
    grid-template-columns: 1fr 574px;
    gap: 55px;

    @media (max-width: $md3+px) {
        grid-template-columns: auto auto;
        justify-content: space-between;
    }

    @media (max-width: $md4+px) {
        margin-top: 200px;
    }

    @media (max-width: 850px) {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: $md6+px) {
        margin-top: 180px;
    }

    .content {

        .head {
            position: relative;
            width: 100%;
            max-width: 451px;

            @media (max-width: $md4+px) {
                max-width: 380px;
            }

            @media (max-width: $md6+px) {
                max-width: 300px;
                margin: 0 auto;
            }

            .logo {
                width: 100%;
            }

            .subtitle {
                position: absolute;
                bottom: -5px;
                left: 0;
            }
        }

        .text {
            max-width: 454px;
            margin-top: 40px;

            @media (max-width: 850px) {
                max-width: 100%
            }

            @media (max-width: $md6+px) {
                display: none;
            }
        }

        .btn {
            @media (max-width: $md6+px) {
                text-align: center;
            }
        }
    }

    .mobText {
        display: none;

        @media (max-width: $md6+px) {
            display: flex;
            margin-top: 45px;
        }
    }

    .image {
        width: 100%;
        max-width: 574px;
    }
}