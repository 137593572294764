@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: all 0.1s;

    @media (max-width: $md3+px) {
        height: 69px;
    }

    @media (max-width: $md6+px) {
        height: 65px;
    }

    &::before {
        content: "";
        background: #fff;
        position: absolute;
        z-index: 8;
        width: 100%;
        height: 0px;
        opacity: 0;
        z-index: -1;
    }

    .container {
        position: relative;
        z-index: 10;
    }

    &Active {
        height: 100%;

        &::before {
            opacity: 1;
            height: 100%;
        }

        .langs {
            display: none;
        }

        .lineWrap {
            display: none;
        }
    }

    .mobileMenu {
        position: relative;
        z-index: 8;
        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;

        @media (min-width: 1221px) {
            display: none;
        }

        .aLink {
            position: relative;
            z-index: 10;
            display: block;
            text-align: center;
            color: #000;
            font-family: $manrope;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;

            &.linkActive {
                color: rgba($color: #000, $alpha: 0.7);
            }
        }

        .btn {
            position: relative;
            z-index: 10;
            justify-content: center;
            display: flex;
            margin-top: 40px;
        }

        &Active {
            display: flex;
            transition: transform 0.5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }

    .headerRow {
        display: grid;
        grid-template-columns: 54px 1fr auto;
        gap: 60px;
        align-items: center;
        padding: 12px 0;

        @media (max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
        }

        &Logo {
            @media (max-width: $md3+px) {
                width: 46px;
            }

            @media(max-width: $md6+px) {
                width: 42px;
            }

            a {
                width: 100%;
                height: 100%;
                display: block;
            }

            img {
                width: 100%;
            }
        }

        &Links {
            display: flex;
            gap: 40px;
            font-family: $manrope;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            justify-content: flex-end;

            @media (max-width: $md3+px) {
                display: none;
            }

            .linkItem {
                position: relative;
                color: #000;
                text-decoration: none;
                display: flex;
                align-items: center;
                gap: 5px;

                &:hover {
                    color: rgba($color: #000, $alpha: 0.7);
                }
            }

            .listWrap {
                position: relative;

                &:hover {
                    .subRoutes {
                        opacity: 1;
                        pointer-events: visible;
                    }

                    svg {
                        transform: rotate(-180deg);
                    }
                }

                svg {
                    transition: all .3s;
                }

                .subRoutes {
                    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

                    position: absolute;
                    background: #F7F7F7;
                    white-space: nowrap;
                    left: -10px;
                    top: 18px;
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    padding-top: 5px;

                    opacity: 0;
                    pointer-events: none;

                    transition: all .3s;

                    a {
                        padding: 6px 10px;
                    }
                }
            }
        }

        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;

            .login {
                padding: 8px 20px;
                background: #9AECFF;
                border-radius: 60px;
                font-family: $manrope;
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;
                color: #434343;
                cursor: pointer;
                transition: all 0.3s;

                @media (max-width: $md3+px) {
                    display: none;
                }

                &:hover {
                    background: #21CAF2;
                }
            }

            .burger {
                cursor: pointer;
                display: flex;
                width: 32px;
                height: 20px;
                position: relative;

                @media (min-width: 1221px) {
                    display: none;
                }

                span:nth-child(1) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #000;
                    border-radius: 10px;
                    transition: all 0.2s;
                }

                span:nth-child(2) {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #000;
                    border-radius: 10px;
                    transition: all 0.2s;
                }

                span:nth-child(3) {
                    position: absolute;
                    bottom: calc(50% - 1px);
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #000;
                    border-radius: 10px;
                    transition: all 0.1s;
                }

                &Active {
                    span:nth-child(1) {
                        top: 10px;
                        transform: rotate(45deg);
                    }

                    span:nth-child(2) {
                        bottom: 8px;
                        transform: rotate(-45deg);
                    }

                    span:nth-child(3) {
                        opacity: 0;
                    }
                }
            }
        }
    }
}

.lineWrap {
    position: relative;
    z-index: 5;
    overflow: hidden;
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;

    @media (max-width: $md3+px) {
        position: absolute;
        left: 0;
        top: 69px;
    }

    @media (max-width: $md6+px) {
        top: 65px;
    }

    .line {
        display: grid;
        grid-template-columns: auto 1fr;

        @media (max-width: $md5+px) {
            display: flex;
            flex-direction: column;
        }

        .join {
            position: relative;
            z-index: 4;
            padding: 10px 60px 10px 0;

            @media (max-width: $md3+px) {
                padding-right: 30px;
            }

            @media (max-width: $md5+px) {
                padding: 8px 0;
                text-align: center;
            }

            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                height: 100%;
                width: calc(100% + 20vw);
                background: #9AECFF;

                @media (max-width: $md5+px) {
                    left: -40px;
                    width: calc(100% + 80px);
                }
            }
        }

        .marquee {
            position: relative;
            z-index: 2;
            padding: 10px 0;

            @media (max-width: $md5+px) {
                padding: 8px 0;
            }

            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                height: 100%;
                width: calc(100% + 20vw);
                background: #21CAF2;

                @media (max-width: $md5+px) {
                    left: -40px;
                    width: calc(100% + 80px);
                }
            }

            :global(.rfm-marquee-container) {
                overflow: visible !important;
            }
        }
    }
}

:global(.Dropdown-control) {
    font-family: $manrope !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    background-color: transparent !important;
    border: none !important;
    color: #000 !important;
    text-transform: uppercase !important;
    padding-right: 20px !important;
    padding-left: 0;
    cursor: pointer !important;

    &:hover {
        box-shadow: none;
    }
}

:global(.Dropdown-menu) {
    font-family: $manrope !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

:global(.Dropdown-arrow) {
    border-color: rgba(0, 0, 0, 1) transparent transparent !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    right: 0 !important;
}

:global(.is-open .Dropdown-arrow) {
    border-color: transparent transparent rgba(0, 0, 0, 1) !important;
}

:global(.Dropdown-option) {
    padding: 6px 11px !important;
}