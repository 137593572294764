@import './var';

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-40 {
    position: relative;
    color: #434343;
    font-family: $manrope;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    padding-left: 30px;

    @media(max-width: $md4+px) {
        font-size: 32px;
    }

    @media(max-width: $md6+px) {
        font-size: 23px;
        padding-left: 20px;
    }

    &::before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0;
        top: 25px;
        background: #9AECFF;

        @media(max-width: $md4+px) {
            top: 18px;
        }

        @media(max-width: $md6+px) {
            width: 8px;
            height: 8px;
            top: 13px;
        }
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-28 {
    color: #434343;
    font-family: $manrope;
    font-size: 28px;
    font-weight: 300;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-28-mark {
    position: relative;
    color: #434343;
    font-family: $manrope;
    font-size: 28px;
    font-weight: 700;
    line-height: 150%;
    padding-left: 30px;

    @media(max-width: $md4+px) {
        font-size: 22px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
        padding-left: 20px;
    }

    &::before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0;
        top: 15px;
        background: #9AECFF;

        @media(max-width: $md4+px) {
            top: 11px;
        }

        @media(max-width: $md6+px) {
            width: 8px;
            height: 8px;
            top: 9px;
        }
    }
}

.font-23 {
    color: #434343;
    font-family: $manrope;
    font-size: 23px;
    font-weight: 600;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }
}

.font-20 {
    color: #434343;
    font-family: $manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-18 {
    color: #434343;
    font-family: $manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-16 {
    color: #434343;
    font-family: $manrope;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-16-mark {
    position: relative;
    color: #434343;
    font-family: $manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    padding-left: 22px;

    @media(max-width: $md6+px) {
        font-size: 14px;
        padding-left: 20px;
    }

    &::before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0;
        top: 6px;
        background: #9AECFF;

        @media(max-width: $md6+px) {
            width: 8px;
            height: 8px;
            top: 7px;
        }
    }
}