@import "../../../styles/var";

.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    overflow: hidden;

    padding: 10px 30px;
    border-radius: 30px;
    background: #9AECFF;

    color: #434343;
    font-family: $manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;

    @media (max-width: $md6+px) {
        font-size: 14px;
    }

    span {
        transition: all .1s;
        width: 100%;
    }

    &:hover {
        background: #21CAF2;
    }

    &:active {
        transform: scale(0.96);
        transition: all 0.1s;
    }

    &.dark {
        background: #434343;
        color: #fff;

        &:hover {
            background: rgba($color: #434343, $alpha: 0.7);
        }
    }
}