@import '../../../styles/var';

.wrap {
    position: relative;
    background: #ADF0FF;
    padding: 80px 40px;
    display: grid;
    grid-template-columns: 1fr 393px;
    gap: 60px;
    margin-top: 300px;

    @media (max-width: $md3+px) {
        grid-template-columns: 1fr 340px;
        margin-top: 140px;
    }

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 40px 60px 0;
    }

    @media (max-width: $md4+px) {
        padding: 40px 40px 0;
    }

    @media (max-width: 600px) {
        width: calc(100% + 36px);
        margin-left: -18px;
        padding: 35px 18px 0;
        margin-bottom: -140px;
    }

    @media (max-width: $md6+px) {
        margin-top: 100px;
        margin-bottom: -100px;
    }

    .content {
        position: relative;
        z-index: 5;

        @media (max-width: $md4+px) {
            text-align: center;
        }

        .title {
            color: #FFF;
            font-family: $manrope;
            font-size: 170px;
            font-weight: 700;
            line-height: 90%;
            margin-left: -11px;

            @media (max-width: $md4+px) {
                font-size: 140px;
                margin-left: -10px;
            }

            @media (max-width: $md6+px) {
                font-size: 112px;
                margin-left: unset;
            }
        }

        .subtitle {
            margin: 20px 0 8px;

            @media (max-width: $md4+px) {
                margin: 12px 0;
            }

            @media(max-width: $md6+px) {
                font-size: 20px;
            }
        }

        .text {
            max-width: 336px;

            @media (max-width: $md4+px) {
                margin: 0 auto;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 405px;
        width: 343px;

        @media (max-width: $md3+px) {
            width: 100%;
            max-width: 300px;
            left: 345px;
        }

        @media (max-width: $md4+px) {
            position: relative;
            margin: 0 auto;
            left: unset;
        }

        @media (max-width: $md6+px) {
            max-width: 230px;
        }
    }
}

.formWrap {
    position: relative;
    z-index: 5;

    .status {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        text-align: center;
        left: 0;
        top: 0;
        transform: scale(0.7);
        opacity: 0;
        pointer-events: none;
        transition: all .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 20px 20px;

        &Error,
        &Success {
            z-index: 3;
        }

        &Process {
            z-index: 2;
        }

        &Icon {
            width: 50px;
            height: 50px;
        }

        &Text {
            color: #000;
            margin-top: 15px;
        }


        &.active {
            transform: scale(1);
            opacity: 1;
            pointer-events: visible;

        }
    }

    .form {
        position: relative;
        z-index: 1;

        &.blur {
            filter: blur(5px);
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 12px;

            @media(max-width: 700px) {
                display: flex;
                flex-direction: column;
            }
        }

        .check {
            display: grid;
            grid-template-columns: 14px 1fr;
            align-items: center;
            gap: 12px;
            margin: 15px 0 30px;

            &.incorrect {
                .checkText {
                    color: red !important;
                }
            }

            &Box {
                position: relative;
                border: 2px solid #434343;
                width: 14px;
                height: 14px;
                cursor: pointer;
                transition: all .1s linear;

                &Active {
                    &::before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        width: 8px;
                        height: 8px;
                        background: #434343;
                    }
                }
            }

            &Text {
                color: #434343;
                text-align: left;
                line-height: 120%;
                font-size: 12px;
                font-weight: 400;

                a {
                    font-weight: 400;
                    color: #434343;
                    text-decoration: underline;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        .submit {
            text-align: center;
        }
    }
}