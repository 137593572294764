@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 697px;
    gap: 35px;
    margin-top: 40px;

    @media (max-width: $md3+px) {
        grid-template-columns: 420px 1fr;
    }

    @media (max-width: $md4+px) {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .head {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @media (max-width: $md4+px) {
            flex-direction: column-reverse;
            gap: 0;
        }

        @media (max-width: 600px) {
            width: calc(100% + 36px);
            margin-left: -18px;
        }

        .buttonsWrap {
            background: #F4F4F4;
            padding: 40px;

            @media (max-width: $md3+px) {
                padding: 20px;
            }

            @media (max-width: $md4+px) {
                padding: 40px;
            }

            @media (max-width: 600px) {
                padding: 25px 18px;
            }

            .subtitle {}

            .buttons {
                margin-top: 20px;

                @media (max-width: $md6+px) {
                    margin-top: 12px;
                }

                .button {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    gap: 20px;
                    align-items: center;
                    padding: 5px 20px;
                    min-height: 52px;
                    cursor: pointer;
                    font-weight: 400;
                    transition: all .1s;

                    @media (max-width: $md6+px) {
                        min-height: 34px;
                        padding: 5px 12px;
                    }

                    &:hover {
                        background: rgba($color: #21CAF2, $alpha: 0.2);
                    }

                    &.active {
                        background: #21CAF2;
                    }

                    .arrow {
                        width: 11px;

                        @media (max-width: $md6+px) {
                            width: 9px;
                        }
                    }
                }
            }
        }

        .banner {
            text-align: center;
            padding: 60px 40px 110px;
            background-image: url('../../../assets/img/ServicesPage/Tabs/banner.avif');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            @media (max-width: $md4+px) {
                padding: 50px 40px;
            }

            @media (max-width: 600px) {
                padding: 50px 18px;
            }

            .logo {
                width: 210px;

                @media (max-width: $md4+px) {
                    width: 180px;
                }

                @media (max-width: $md6+px) {
                    width: 150px;
                }
            }

            .text {
                margin-top: 20px;

                @media (max-width: $md4+px) {
                    margin: 20px auto 0;
                    max-width: 380px;
                }

                @media (max-width: $md6+px) {
                    max-width: 324px;
                }
            }
        }
    }

    .content {

        .tab {
            display: none;

            &.active {
                display: block;
            }

            .title {
                margin-bottom: 30px;
            }

            .image {
                width: 100%;
                margin-bottom: 30px;
            }

            .text {
                font-weight: 400;
            }

            .desc {
                margin-top: 30px;
                font-weight: 400;
                background: #21CAF2;
                text-align: center;
                padding: 10px 18px;

                @media (max-width: $md6+px) {
                    margin-top: 20px;
                    padding: 10px 50px;
                }
            }

            .mainBlock {
                margin-top: 50px;

                .title {
                    @media (max-width: $md6+px) {
                        margin-bottom: 12px;
                    }
                }
            }

            .blocks {
                display: flex;
                flex-direction: column;
                gap: 50px;

                @media (max-width: $md6+px) {
                    gap: 30px;
                }

                .block {
                    .subtitle {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}