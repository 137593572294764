@import '../../../styles/var';

.wrap {

    .title {}

    .cards {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 40px;

        @media (max-width: $md3+px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $md5+px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 0;
        }

        @media (max-width: $md6+px) {
            margin-top: 30px;
            width: calc(100% + 36px);
            margin-left: -18px;
        }

        .card {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            border-radius: 10px;
            padding: 35px 40px;

            @media (max-width: $md3+px) {
                width: auto !important;
                padding: 30px;
            }

            @media (max-width: $md5+px) {
                min-height: unset !important;
                border-radius: 0;
            }

            @media (max-width: $md6+px) {
                padding: 25px 18px;
            }

            .content {
                position: relative;
                z-index: 5;

                .desc {
                    margin-top: 12px;
                }
            }

            .image {
                position: absolute;
                z-index: 2;
                right: 0;
                top: 0;
            }

            &_01 {
                background: rgba(154, 236, 255, 0.81);

                .desc {
                    max-width: 441px;
                }

                .image {
                    height: 100%;

                    @media (max-width: $md3+px) {
                        display: none;
                    }
                }
            }

            &_02 {
                background: #EBEBEB;

                .image {
                    height: 222px;

                    @media (max-width: $md6+px) {
                        height: 180px;
                    }
                }
            }

            &_03 {
                background: #EBEBEB;

                .image {
                    height: 100%;
                    opacity: 0.6;
                }
            }

            &_04 {
                background: #21CAF2;

                @media (max-width: $md5+px) {
                    grid-row: 3/3;
                }

                .desc {
                    max-width: 480px;
                }
            }

            &_01,
            &_02 {
                min-height: 274px;
            }

            &_03,
            &_04 {
                min-height: 240px;
            }

            &_01,
            &_04 {
                width: 700px;
            }

            &_02,
            &_03 {
                width: calc(100% - 700px - 15px);

                .desc {
                    max-width: 409px;
                }
            }
        }
    }
}