@import '../../../styles/var';

.wrap {
    display: grid;
    grid-template-columns: 420px 1fr;
    gap: 70px;

    @media (max-width: $md3+px) {
        grid-template-columns: 400px 1fr;
        gap: 60px;
    }

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    @media (max-width: $md6+px) {
        gap: 30px;
    }

    .head {

        .title {}

        .text {
            margin-top: 8px;
        }

        .btn {
            @media (max-width: $md4+px) {
                display: none;
            }
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px 80px;

        @media (max-width: $md3+px) {
            gap: 40px;
        }

        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        .card {

            @media (max-width: 600px) {
                display: grid;
                grid-template-columns: 55px 1fr;
                gap: 20px;
            }

            @media (max-width: $md6+px) {
                grid-template-columns: 45px 1fr;
                gap: 10px;
            }

            .icon {
                width: 65px;

                @media (max-width: $md4+px) {
                    width: 55px;
                }

                @media (max-width: $md6+px) {
                    width: 45px;
                }
            }

            .subtitle {
                margin: 25px 0 12px;

                @media (max-width: 600px) {
                    margin-top: 0;
                }
            }

            .desc {}
        }
    }
}