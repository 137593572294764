@import "../../../styles/var";

.wrap {
    background: #F3F3F3;
    padding: 25px 0 40px;

    .footer {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .logo {
            margin: 0 auto;
            width: 54px;

            @media (max-width: $md3+px) {
                width: 46px;
            }

            @media(max-width: $md6+px) {
                width: 42px;
            }
        }

        a {
            text-decoration: none;
            display: block;
            font-weight: 400;
            color: #434343;

            &:hover {
                opacity: 0.7;
            }
        }

        p {
            font-weight: 400;
        }
    }
}